/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:136e4e37-5cb1-4d95-8024-67ffde5132e3",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_6OgFksLGg",
    "aws_user_pools_web_client_id": "2g8jrg514799nrog3fs0elhtmm",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://odcihp78f7.execute-api.ca-central-1.amazonaws.com/nwhc",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://ohzbsl2b5a.execute-api.ca-central-1.amazonaws.com/nwhc",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-nwhc",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-nwhc",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-nwhc",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile134944-nwhc",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-nwhc",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d3drmu1if5mx3k.cloudfront.net"
};


export default awsmobile;
